import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { createStyles, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { Alert } from '@material-ui/lab';

import {
  adminApproveCompanyVerification,
  adminDenyCompanyVerification,
} from '../../actions/companyActions';
import { showNotificationAction } from '../../reducers/notificationReducer';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    contentBox: {
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        margin: '2rem auto',
      },
      [theme.breakpoints.up('md')]: {
        width: '50rem',

        margin: '2rem auto',
      },
      padding: '2rem',
      borderRadius: '5px',
      fontSize: '1rem',
      color: '#333',
      position: 'relative',
    },
    remunerationBox: {
      borderRadius: '5px',
      boxShadow: ' 0 0 2px 2px rgba(32, 35, 42, 0.5)',
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: '-20rem',
        top: '10rem',
        width: '20rem',
      },
    },
    remunerationBoxOne: {
      padding: '1rem',
      color: '#fff',
      backgroundColor: '#6fbf73',
    },
    center: {
      textAlign: 'center',
    },
    Share: {
      position: 'fixed',
      right: '1rem',
      top: '15%',
    },
    inlineBox: {
      whiteSpace: 'normal',
    },

    message: {
      whiteSpace: 'normal',
    },
    back: {
      position: 'fixed',
      right: '1rem',
      bottom: '6rem',
    },
    companyBox: {
      padding: '1rem',

      [theme.breakpoints.up('sm')]: {
        // 当屏幕宽度 >= 600px 时应用的样式
        // width: '100%',
        // margin: '2rem auto',
      },
      [theme.breakpoints.up('md')]: {
        borderRadius: '5px',
        boxShadow: ' 0 0 5px 5px rgba(32, 35, 42, 0.5)',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        position: 'fixed',
        right: '1rem',
        top: '30%',
      },
    },
    fontsIcon: {
      display: 'inline-block',
      height: '1rem',
      width: '1rem',
      borderRadius: '50%',
      marginTop: '0.2rem',
      marginRight: '0.5rem',
      boxShadow: '0 0 0.5rem rgba(32, 35, 42, 0.5)',
    },
    textContent: {
      padding: '1rem 0',
      display: 'flex',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    textContent2: {
      width: '90%',
      display: 'flex',
      flexWrap: 'wrap',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },

    displayFlex: {
      display: 'flex',
    },
    remunerationInfo: {
      padding: '0 1rem',
      lineHeight: '1.5rem',
    },

    borderBottom: {
      margin: '0.5rem 0',
      borderBottom: '1px solid rgba(32, 35, 42, 0.3)',
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    examine: {
      textAlign: 'center',
    },
    div: {
      color: 'inherit',
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      textRendering: 'optimizeLegibility',
      lineHeight: '1.1',
      fontSize: '1.58316rem',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '0.5rem',
      },
    },
    divLevel2: {
      color: 'inherit',
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      textRendering: 'optimizeLegibility',
      lineHeight: '1.1',
      fontSize: '1.18316rem',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '0.5rem',
      },
    },
    companyDiv: {
      padding: '1rem',
      color: '#666666',
    },
    chip: {
      margin: '0.5rem',
    },
    dialogWidth: {
      padding: '0',
      width: '100%',
    },
    bidingView: {
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
      margin: '0 auto',
      scrollbarWidth: 'none',
    },
    spanColor: {
      color: 'rgba(0, 0, 0, 0.5)',
      margin: '0 1rem',
    },
    content: {
      margin: '1rem 0',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    wordContent: {
      margin: '1rem 0',
      color: '#666666',
      lineHeight: '1.5rem',
      fontSize: '0.85rem',
      '& > div': {
        margin: '0.5rem 0',
      },
    },
    annexImg: {
      width: '10rem',
      height: '10rem',
    },
  });

const companyInfoDetailsView = ({ classes, companyData, onClosed }: Props): ReactElement => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [isDialog, setIsDialog] = useState(false);
  const [isFailDialog, setIsFailDialog] = useState(false);
  const [comment, setComment] = useState('');

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setComment(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // 审核通过
  const handelCompanyAuthentication = async () => {
    if (!companyData?.id) {
      return dispatch(
        showNotificationAction({
          severity: 'error',
          message: '没有获取到企业信息',
        })
      );
    }
    const value = await dispatch(adminApproveCompanyVerification(companyData?.id));
    if (value) {
      dispatch(
        showNotificationAction({
          severity: 'success',
          message: '企业审核成功',
        })
      );
      onClosed ? onClosed() : null;
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '企业审核失败',
        })
      );
    }
    setIsDialog(false);
  };

  // 审核不通过
  const handelCompanyFailAuthentication = async () => {
    if (!companyData?.id) {
      return dispatch(
        showNotificationAction({
          severity: 'error',
          message: '没有获取到企业信息',
        })
      );
    }
    const value = await dispatch(adminDenyCompanyVerification(companyData?.id, comment));
    if (value) {
      dispatch(
        showNotificationAction({
          severity: 'success',
          message: '已拒绝企业审核',
        })
      );
      onClosed ? onClosed() : null;
    } else {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: '操作失败',
        })
      );
    }
    setIsFailDialog(false);
  };

  const projectDetail = (
    <div className={classes.contentBox}>
      <div className={classes.div}>{companyData?.basicInfo?.registrationName}</div>
      <div className={classes.textContent}>
        社会统编码：{companyData?.basicInfo?.registrationId}
        {/* <span className={classes.spanColor}>|</span> */}
      </div>
      <Divider />
      <div className={classes.wordContent}>
        <div>法定代表人：{companyData?.basicInfo?.enterpriseLegalPerson}</div>

        <div>联系电话：{companyData?.basicInfo?.contactPhone}</div>
      </div>
      <Divider />

      <div className={classes.content}>
        <div className={classes.divLevel2}>附件</div>
        <div className={classes.wordContent}>
          {companyData?.supplementInfo?.certificateFileList &&
          companyData?.supplementInfo?.certificateFileList?.length > 0
            ? companyData?.supplementInfo?.certificateFileList?.map((item, i) => {
                return <img src={item.file} key={i} className={classes.annexImg}></img>;
              })
            : null}
        </div>
      </div>

      <p className={classes.examine}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => {
            setIsDialog(true);
          }}
        >
          <PlaylistAddCheckIcon />
          审核通过
        </Button>
        <span className={classes.spanColor}></span>
        <Button
          type="button"
          variant="contained"
          onClick={() => {
            setIsFailDialog(true);
          }}
        >
          <NotInterestedIcon></NotInterestedIcon>
          审核不通过
        </Button>
      </p>

      <Dialog
        open={isDialog}
        scroll="paper"
        onClose={() => {
          setIsDialog(false);
        }}
      >
        <DialogTitle>企业审核</DialogTitle>
        <DialogContent>确认通过企业信息审核吗？</DialogContent>
        <DialogActions style={{ textAlign: 'center' }}>
          <Button onClick={handelCompanyAuthentication} variant="contained" color="primary">
            是
          </Button>
          <Button
            onClick={() => {
              setIsDialog(false);
            }}
            variant="contained"
          >
            否
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isFailDialog}
        scroll="paper"
        onClose={() => {
          setIsFailDialog(false);
        }}
      >
        <DialogTitle>企业审核</DialogTitle>
        <DialogContent>
          确认不通过企业信息审核吗？
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            label="审核不通过反馈"
            value={comment}
            onChange={handleCommentChange}
          />
        </DialogContent>
        <DialogActions style={{ textAlign: 'center' }}>
          <Button onClick={handelCompanyFailAuthentication} variant="contained" color="primary">
            是
          </Button>
          <Button
            onClick={() => {
              setIsFailDialog(false);
              setComment('');
            }}
            variant="contained"
          >
            否
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity="success">
          123
        </Alert>
      </Snackbar>
    </div>
  );
  return <>{projectDetail}</>;
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  requestId?: number;
  examine?: boolean;
  overHideShare?: boolean;
  companyData?: CompanyVerification;
  onClosed?: Function;
}

export default withStyles(styles)(companyInfoDetailsView);
